<script setup lang="ts">
import { ArrowLeftIcon, MenuIcon } from '@heroicons/vue/solid/index.js'
import { $loginState, $me } from '@/services/me'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useStore } from '@nanostores/vue'
import whatsapp from '../assets/whatsapp.svg'
import settings from '../../data/settings.json'

interface Props {
  showBackButton?: boolean
  useCustomCallback?: boolean
}

const emit = defineEmits<{
  (e: 'back', event: any): void
}>()

const props = withDefaults(defineProps<Props>(), {
  showBackButton: false,
  useCustomCallback: false,
})

const navigateBack = (event: Event) => {
  event.preventDefault()
  event.stopPropagation()

  if (!props.useCustomCallback) {
    window.location.href = '/'
  } else {
    emit('back', event)
  }
}

const whatsapp_offer_group_url = settings?.WHATSAPP_OFFER_GROUP

const me = useStore($me)
const loginState = useStore($loginState)
const current_hour = new Date().getHours()
let salutation = 'Boa noite'
if (current_hour >= 12 && current_hour < 19) {
  salutation = 'Boa tarde'
} else if (current_hour >= 4 && current_hour < 12) {
  salutation = 'Bom dia'
}
</script>

<template>
  <button type="button" class="text-black py-2 px-4" v-if="showBackButton" @click="navigateBack">
    <ArrowLeftIcon class="h-5 w-5" />
  </button>

  <Menu as="div" class="relative inline-block text-left" v-else>
    <div>
      <MenuButton
        id="menuButton"
        class="inline-flex justify-center items-center content-center w-full rounded-md bg-white text-sm font-medium text-gray-700 relative space-x-2 px-4 py-2.5"
      >
        <MenuIcon class="h-5 w-5" aria-hidden="true" />

        <span class="absolute flex h-2 w-2 right-1.5 top-1.5">
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"
          ></span>
          <span class="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
        </span>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="z-30 origin-top-left absolute left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <div
            v-if="loginState !== 'loading' && me?.club_member"
            class="mx-1 bg-yellow-400 text-purple-600 flex rounded"
          >
            <div class="py-2 leading-tight text-center flex-1 text-xs">
              {{ salutation }}{{ me?.first_name ? ', ' + me?.first_name : '' }}<br />
              Aproveite seu clube!
            </div>

            <img
              src="https://img.imageboss.me/mara/width/80/trim:60,format:auto/assets/c0b9f9c7-8010-4f70-8f4d-0c8e06f5cc22"
              alt="Clube Mara"
              class="bg-purple-800 pt-2 pr-1 pl-3 pb-2 object-center object-contain rounded-tr rounded-br"
              width="60"
            />
          </div>

          <div
            v-else-if="loginState !== 'loading' && !me?.club_member"
            class="mx-1 bg-yellow-400 text-purple-600 flex rounded"
          >
            <div class="py-2 leading-tight text-center flex-1 text-xs">
              {{ salutation }}{{ me?.first_name ? ', ' + me?.first_name : '' }}<br />
              Aproveite os preços Mara!
            </div>
          </div>

          <div v-else class="mx-1 bg-yellow-400 text-purple-600 flex rounded">
            <div class="p-2 leading-tight text-center flex-1 text-xs animate-pulse">
              <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="h-2 bg-slate-400 rounded col-span-2"></div>
                  <div class="h-2 bg-slate-400 rounded col-span-1"></div>
                </div>
                <div class="h-2 bg-slate-400 rounded"></div>
              </div>
            </div>
          </div>

          <MenuItem class="cursor-pointer">
            <a href="/conta" class="text-gray-700 block mt-1 px-4 py-3 text-sm">
              Minha Conta
            </a>
          </MenuItem>

          <MenuItem class="cursor-pointer" v-if="!me?.club_member">
            <a href="https://clube.mara.com.br" class="text-gray-700 block px-4 py-3 text-sm"
              >Clube Mara</a
            >
          </MenuItem>

          <MenuItem class="cursor-pointer">
            <a
              :href="whatsapp_offer_group_url"
              target="_blank"
              class="text-gray-700 flex px-4 py-3 text-sm justify-between items-center"
            >
              <span>Grupo de Ofertas</span>
              <img class="h-5 w-5 invert inline-block" :src="whatsapp.src" />
            </a>
          </MenuItem>

          <MenuItem class="cursor-pointer">
            <div class="relative">
              <span class="absolute flex h-2 w-2 left-1 top-4">
                <span
                  class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"
                ></span>
                <span class="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
              </span>
              <a
                href="https://mara.com.br/folhetos-de-ofertas"
                class="text-gray-700 block px-4 py-3 text-sm"
                >Folhetos de Ofertas</a
              >
            </div>
          </MenuItem>

          <MenuItem class="cursor-pointer">
            <a href="https://parceiro.mara.com.br/" class="text-gray-700 block px-4 py-3 text-sm"
              >Seja um Parceiro</a
            >
          </MenuItem>

          <MenuItem class="cursor-pointer">
            <a href="/onde-entregamos" class="text-gray-700 block px-4 py-3 text-sm"
              >Onde entregamos</a
            >
          </MenuItem>

          <MenuItem class="cursor-pointer">
            <a
              href="https://wa.me/5511935017868/?text=Oi+Mara!"
              target="_blank"
              class="text-gray-700 flex px-4 py-3 text-sm justify-between items-center"
            >
              <span>WhatsApp de Suporte</span>
              <img class="h-5 w-5 invert inline-block" :src="whatsapp.src" />
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
